import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, you'll learn about syntactic dependency trees, graphs, and methods for parsing such structures.
You'll have an opportunity to apply this information to implement an `}<strong parentName="p">{`arc-standard transition-based dependency parser`}</strong>{`.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <ul>
      <li parentName="ul">{`Label a syntactic dependency parse using the `}<a parentName="li" {...{
          "href": "https://universaldependencies.org/u/dep/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`universal dependency relations`}</a></li>
      <li parentName="ul">{`Identify cases of projective and non-projective dependency parses`}</li>
      <li parentName="ul">{`Explain the difference between stacks and queues`}</li>
      <li parentName="ul">{`Compare and contrast `}<strong parentName="li">{`oracle`}</strong>{` implementations for training and prediction`}</li>
      <li parentName="ul">{`Identify common features used to train transition-based statistical parsers`}</li>
      <li parentName="ul">{`Describe properties and limitations of the arc-standard transition system`}</li>
      <li parentName="ul">{`Identify valid transition sequences for a parse using the arc-standard transition system`}</li>
      <li parentName="ul">{`Implement the `}<strong parentName="li">{`shift`}</strong>{`, `}<strong parentName="li">{`left-arc`}</strong>{`, and `}<strong parentName="li">{`right-arc`}</strong>{` operations in the `}<strong parentName="li">{`arc-standard transition system`}</strong></li>
    </ul>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <h2 {...{
      "id": "videos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#videos",
        "aria-label": "videos permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Videos`}</h2>
    <p>{`Be sure to watch the videos in the playlist linked below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PL2acInhHkjsaWRpFw6iwr1Llx9sEdjuf-",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Unit playlist`}</a></li>
    </ul>
    <h2 {...{
      "id": "slides",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#slides",
        "aria-label": "slides permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Slides`}</h2>
    <p>{`These slides correspond to the videos for the unit:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/6-1-dependency-trees-slides.pdf" target="_blank">Dependency trees (slides)</a>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/6-2a-arc-standard-parsing-slides.pdf" target="_blank">Arc-standard parsing (slides)</a>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.com/courses/snlp-1/slides/6-2b-arc-eager-parsing-slides.pdf" className="disabled" target="_blank">Arc-eager parsing (slides)</a>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.com/courses/snlp-1/slides/6-2c-alternative-transition-systems-parsing-slides.pdf" className="disabled" target="_blank">Alternative transition systems (slides)</a>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/6-3-graph-parsing-slides.pdf" className="disabled" target="_blank">Graph-based parsing (slides)</a>
      </li>
    </ul>
    <h2 {...{
      "id": "readings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#readings",
        "aria-label": "readings permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Readings`}</h2>
    <p>{`The excerpts linked below provide a nice overview of the tasks we preview in this unit:`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
  <tbody>
    <tr>
        <td>
          <p>Dependency Parsing</p>
        </td>
        <td>
          <ul>
            <li>dependency trees</li>
            <li>transition-based parsing</li>
            <li>graph-based parsing</li>
          </ul>
        </td>
        <td>
          <p><a href="https://public.parsertongue.org/readings/slp3/15.pdf">Speech and Language Processing Ch 15</a></p>
          <p>OPTIONAL: <a href="https://www.aclweb.org/anthology/C08-1095/">Shift-Reduce Dependency DAG Parsing (Sagae &#38; Tsujii, 2008)</a></p>
        </td>
    </tr>
  </tbody>
    </HTMLTable>
    {/* # Learn
     :::note NOTE 
     _Complete these tutorials in the order listed._
     :::
     */}
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arizona.openclass.ai/resource/assignment-6017dadfff512a21f731a337",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Review what you've learned (arc-standard parsing)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      